export default {
  header: {
    templateUrl: './top.header.component.html',
    styleUrls: ['./top.header.component.scss'],
  },
  home: {
    templateUrl: './rewards.home.component.html',
    styleUrls: ['./rewards.home.component.scss'],
  },
  about_contactUs: {
    templateUrl: './bstl.contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
  },
  editProfile: {
    templateUrl: './bstl.edit.component.html',
  },
  inputForm: {
    templateUrl: './bstl-input-form.component.html',
  },
  loginCarousel: {
    templateUrl: './full-page.side-carousel.component.html',
    styleUrls: ['./full-page.side-carousel.component.scss'],
  },
};
