<div *ngIf="!!eCard" class="box--img">
  <img class="img-fluid img--ecard" [src]="eCard.image" />
  <div class="btn--remove" (click)="removeECard.emit()">
    <i class="bi bi-x-octagon-fill"></i>
  </div>
</div>
<div *ngIf="!!gif" class="box--img">
  <img class="img-fluid img--gif" [src]="gif.images.fixed_height_small.url" />
  <div class="btn--remove" (click)="removeGif.emit()">
    <i class="bi bi-x-octagon-fill"></i>
  </div>
</div>
<div *ngIf="!!gif2" class="box--img">
  <img class="img-fluid img--gif" [src]="gif2" />
  <div class="btn--remove" (click)="removeGif.emit()">
    <i class="bi bi-x-octagon-fill"></i>
  </div>
</div>
<div *ngIf="!!images" class="row g-0 gap-2">
  <div *ngFor="let img of images" class="col-auto box--img">
    <div class="div" *ngIf="img.type == 'image'">
      <img class="img-fluid img--ecard" [src]="img.imgBase64" />
      <div class="btn--remove" (click)="removeImgFile.emit(img)">
        <i class="bi bi-x-octagon-fill"></i>
      </div>
    </div>
  </div>
</div>
<div class="d-flex btn--uploads">
  <div
    *ngIf="enableECard && screenSetting?.support_media"
    class="d-flex btn--upload ecard"
    [ngClass]="!gif && !gif2 ? '' : 'disable'"
    (click)="onOpenECard.emit()"
  >
    <img class="img--upload" src="assets/img/ecard.svg" />
    {{ "common.eCard" | translate }}
  </div>
  <div
    *ngIf="!disableGIF && screenSetting?.show_gif"
    class="d-flex btn--upload gif"
    [ngClass]="!eCard && (!images || !images.length) ? '' : 'disable'"
    (click)="onOpenGIF.emit()"
  >
    <img class="img--upload" src="assets/img/gif2.svg" />
    GIF
  </div>
  <div
    *ngIf="
      !disableImages &&
      (!only1Image || (!!only1Image && (!images || !images.length))) &&
      screenSetting?.support_media
    "
    class="d-flex btn--upload img"
    [ngClass]="!gif && !gif2 ? '' : 'disable'"
    (click)="onOpenImage.emit()"
  >
    <img class="img--upload" src="assets/img/image.svg" />
    {{ customLabelUploadImages || ("common.image" | translate) }}
  </div>
</div>
