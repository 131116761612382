export default {
  address: {
    contactNumberLength: 10, // include start with zero or not
    contactNumberMinLength: 11,
    contactNumberStartWithZero: true,
    emailRequired: false,
    hidePin: false,
    hideCountry: false,
    fixCountry: 'TH',
    pinCodeRequired: true,
    pinCodeLength: 5,
    pinCodeDigitOnly: true,
    addressLine2Mandatory: true,
  },
  allowInsufficientPoint: false,
  app: 'bstl',
  applyBgColorFromDjango: false,
  appName: 'BSTL',
  contactInfo: {
    en: '',
    th: '',
  },
  customFeeds: true,
  customRewardCategory: true,
  defaultCurrency: 'HKD',
  defaultLanguage: 'th',
  defaultUserIcon: 'assets/img/user.png',
  editFormDisabled: true,
  employeeIdPrefix: 'bstl',
  enableNomination: false,
  enforceDefaultLanguage: true,
  expiryPoint: true,
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  freshchat: {
    isShow: false,
    pages: ''
  },
  forgotPasswordLink: false,
  footer: {
    dynamicLogo: false,
    logoLink: '',
    privacyLink: 'https://www.bridgestone.co.th/th/about/privacy',
    tncLink: 'https://www.bridgestone.co.th/th/about/terms-of-use',
    userGuide: 'https://cerrapoints.com/media/attachment/1692168434.pdf',
  },
  header: {
    dynamicBg: false,
    dynamicLogo: false,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: true,
  },
  hideSFStoreSFLocker: true,
  home: {
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
  },
  languageAvailable: ['en', 'th'],
  languageLabel: {
    en: 'English',
    th: 'แบบไทย',
  },
  leftNavigate: null,
  loginReadMore: false,
  loginTermsCondition: true,
  modules: {
    home: true,
    appreciate: false,
    feeds: false,
    rewards: true,
    myWallet: false,
    settings: false,
    pointsHistory: true,
    cart: true,
    receipts: false,
    leaderboard: false,
  },
  panel: {
    link: ['/profile/edit'],
    support: false,
    dealerManagement: true,
  },
  profileEdit: {
    editFormDisabled: true,
    navigateToHome: false,
    departmentName: true,
    taxId: true,
    taxPercentage: true,
    registrationDate: true,
  },
  rating: false,
  recognitionRecommendation: false,
  registerNewAccount: false,
  rewardDefaultSort: 'lh',
  rewardDetailBackRoute: ['/home'],
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
    },
    sorting: true,
    title: 'common.reward.n',
  },
  showDepartmentName: true,
  showEmployeeId: true,
  showExpiringPoints: true,
  showSpecialDeal: true,
  showUsername: false,
  sideCarousel: [
    {
      img: 'assets/img/slider/1.webp',
      title: '',
      description:
        'B-point ยิ่งซื้อ ยิ่งได้ สะสมคะแนน แลกรับของรางวัลมากมาย สิทธิพิเศษสำหรับร้านค้าช่วง ที่ซื้อยาง BRIDGESTONE, FIRESTONE และ DAYTON ผ่านตัวแทนจำหน่ายค้าส่ง WSP',
    },
  ],
  topMenu: true,
};
